// semi inhouse code

/* ============================================================
 * bootstrap-dropdown.js v2.1.1
 * http://twitter.github.com/bootstrap/javascript.html#dropdowns
 * ============================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ============================================================ */

!(function ($) {
  "use strict"; // jshint ;_;

  /* DROPDOWN CLASS DEFINITION
   * ========================= */

  var toggle = "[data-toggle=dropdown]",
    Dropdown = function (element) {
      var $el = $(element).on("click.dropdown.data-api", this.toggle);
      $("html").on("click.dropdown.data-api", function () {
        $el.parent().removeClass("open");
      });
    };

  Dropdown.prototype = {
    constructor: Dropdown,

    toggle: function (e) {
      var $this = $(this),
        $parent,
        isActive;

      if ($this.is(".disabled, :disabled")) return;

      $parent = getParent($this);

      isActive = $parent.hasClass("open");

      clearMenus();

      if (!isActive) {
        $parent.toggleClass("open");
        $this.focus();
      }

      return false;
    },

    keydown: function (e) {
      var $this, $items, $active, $parent, isActive, index;

      if (!/(38|40|27)/.test(e.keyCode)) return;

      $this = $(this);

      e.preventDefault();
      e.stopPropagation();

      if ($this.is(".disabled, :disabled")) return;

      $parent = getParent($this);

      isActive = $parent.hasClass("open");

      if (!isActive || (isActive && e.keyCode == 27)) return $this.click();

      $items = $("[role=menu] li:not(.divider) a", $parent);

      if (!$items.length) return;

      index = $items.index($items.filter(":focus"));

      if (e.keyCode == 38 && index > 0) index--; // up
      if (e.keyCode == 40 && index < $items.length - 1) index++; // down
      if (!~index) index = 0;

      $items.eq(index).focus();
    },
  };

  function clearMenus() {
    getParent($(toggle)).removeClass("open");
  }

  function getParent($this) {
    var selector = $this.attr("data-target"),
      $parent;

    if (!selector) {
      selector = $this.attr("href");
      selector = selector && /#/.test(selector) && selector.replace(/.*(?=#[^\s]*$)/, ""); //strip for ie7
    }

    $parent = $(selector);
    $parent.length || ($parent = $this.parent());

    return $parent;
  }

  /* DROPDOWN PLUGIN DEFINITION
   * ========================== */

  $.fn.dropdown = function (option) {
    return this.each(function () {
      var $this = $(this),
        data = $this.data("dropdown");
      if (!data) $this.data("dropdown", (data = new Dropdown(this)));
      if (typeof option == "string") data[option].call($this);
    });
  };

  $.fn.dropdown.Constructor = Dropdown;

  /* APPLY TO STANDARD DROPDOWN ELEMENTS
   * =================================== */

  $(function () {
    $("html").on("click.dropdown.data-api", clearMenus);
    $("body")
      .on("click.dropdown touchstart.dropdown.data-api", ".dropdown form", function (e) {
        e.stopPropagation();
      })
      .on("click.dropdown.data-api touchstart.dropdown.data-api", toggle, Dropdown.prototype.toggle)
      .on("keydown.dropdown.data-api touchstart.dropdown.data-api", toggle + ", [role=menu]", Dropdown.prototype.keydown);
  });
})(window.jQuery);
/* ============================================================
 * bootstrap-button.js v2.1.1
 * http://twitter.github.com/bootstrap/javascript.html#buttons
 * ============================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ============================================================ */

!(function ($) {
  "use strict"; // jshint ;_;

  /* BUTTON PUBLIC CLASS DEFINITION
   * ============================== */

  var Button = function (element, options) {
    this.$element = $(element);
    this.options = $.extend({}, $.fn.button.defaults, options);
  };

  Button.prototype.setState = function (state) {
    var d = "disabled",
      $el = this.$element,
      data = $el.data(),
      val = $el.is("input") ? "val" : "html";

    state = state + "Text";
    data.resetText || $el.data("resetText", $el[val]());

    $el[val](data[state] || this.options[state]);

    // push to event loop to allow forms to submit
    setTimeout(function () {
      state == "loadingText" ? $el.addClass(d).attr(d, d) : $el.removeClass(d).removeAttr(d);
    }, 0);
  };

  Button.prototype.toggle = function () {
    var $parent = this.$element.closest('[data-toggle="buttons-radio"]');

    $parent && $parent.find(".active").removeClass("active");

    this.$element.toggleClass("active");
  };

  /* BUTTON PLUGIN DEFINITION
   * ======================== */

  $.fn.button = function (option) {
    return this.each(function () {
      var $this = $(this),
        data = $this.data("button"),
        options = typeof option == "object" && option;
      if (!data) $this.data("button", (data = new Button(this, options)));
      if (option == "toggle") data.toggle();
      else if (option) data.setState(option);
    });
  };

  $.fn.button.defaults = {
    loadingText: "loading...",
  };

  $.fn.button.Constructor = Button;

  /* BUTTON DATA-API
   * =============== */

  $(function () {
    $("body").on("click.button.data-api", "[data-toggle^=button]", function (e) {
      var $btn = $(e.target);
      if (!$btn.hasClass("btn")) $btn = $btn.closest(".btn");
      $btn.button("toggle");
    });
  });
})(window.jQuery);
/* ========================================================
 * bootstrap-tab.js v2.0.4
 * http://twitter.github.com/bootstrap/javascript.html#tabs
 * ========================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ======================================================== */

!(function ($) {
  "use strict"; // jshint ;_;

  /* TAB CLASS DEFINITION
   * ==================== */

  var Tab = function (element) {
    this.element = $(element);
  };

  Tab.prototype = {
    constructor: Tab,

    show: function () {
      var $this = this.element,
        $ul = $this.closest("ul:not(.dropdown-menu)"),
        selector = $this.attr("data-target"),
        previous,
        $target,
        e;

      if (!selector) {
        selector = $this.attr("href");
        selector = selector && selector.replace(/.*(?=#[^\s]*$)/, ""); //strip for ie7
      }

      if ($this.parent("li").hasClass("active")) return;

      previous = $ul.find(".active a").last()[0];

      e = $.Event("show", {
        relatedTarget: previous,
      });

      $this.trigger(e);

      if (e.isDefaultPrevented()) return;

      $target = $(selector);

      this.activate($this.parent("li"), $ul);
      this.activate($target, $target.parent(), function () {
        $this.trigger({
          type: "shown",
          relatedTarget: previous,
        });
      });
    },

    activate: function (element, container, callback) {
      var $active = container.find("> .active"),
        transition = callback && $.support.transition && $active.hasClass("fade");

      function next() {
        $active.removeClass("active").find("> .dropdown-menu > .active").removeClass("active");

        element.addClass("active");

        if (transition) {
          element[0].offsetWidth; // reflow for transition
          element.addClass("in");
        } else {
          element.removeClass("fade");
        }

        if (element.parent(".dropdown-menu")) {
          element.closest("li.dropdown").addClass("active");
        }

        callback && callback();
      }

      transition ? $active.one($.support.transition.end, next) : next();

      $active.removeClass("in");
    },
  };

  /* TAB PLUGIN DEFINITION
   * ===================== */

  $.fn.tab = function (option) {
    return this.each(function () {
      var $this = $(this),
        data = $this.data("tab");
      if (!data) $this.data("tab", (data = new Tab(this)));
      if (typeof option == "string") data[option]();
    });
  };

  $.fn.tab.Constructor = Tab;

  /* TAB DATA-API
   * ============ */

  $(function () {
    $("body").on("click.tab.data-api", '[data-toggle="tab"], [data-toggle="pill"]', function (e) {
      e.preventDefault();
      $(this).tab("show");
    });
  });
})(window.jQuery);
